<template>
  <div>
    <div class="buttons-container filters">
      <Button title="Pas Eligible" type="primary" :active="statusShowed.includes(22)" @click="statusShowed=[21,22]"/>
      <Button title="En attente" type="primary" :active="statusShowed.includes(23)" @click="statusShowed=[23]"/>
      <Button title="En validation" type="primary" :active="statusShowed.includes(24)" @click="statusShowed=[24]"/>
    </div>
    
    <MonitoringDisplayBar :columns="columns" />

    <div class="monitoring-table">
      <table aria-describedby="monitoring-table">
        <thead>
          <tr>
            <th v-for="(group, i) in displayColumns" :key="`cols-${i}`" :colspan="group.fields.length" class="group group-head">
              {{ group.title }}
            </th>
          </tr>
          <tr>
            <template v-for="(group, i) in displayColumns">
              <template v-for="(col, j) in group.fields">
                <th :key="`col-${i}-${j}`"
                  :class="{sortable: col.sortable, group: j === 0}"
                  @click="sort(group, col)"
                >
                  {{ col.title }}
                </th>
              </template>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(patient, i) in sortedPatients" :key="`patient-${i}`">
            <template v-for="(column,cName) in columns" >
              <template v-if="column.display">
                <template v-for="field in column.fields">
                  <td v-if="field.type === 'button'" :key="`patientCell-${cName}-${field.name}`"><Button class="secondary" :title="field.name" @click="field.action(patient)"/></td>
                  <MonitoringPatientCell
                    v-else
                    :key="`patientCell-${cName}-${field.name}`"
                    :cellType="field.type"
                    :data="patient[field.name]||''"
                    :options="field.options"
                    :cellTitle="field.title"
                    :editable="field.editable"
                    @edit="value=>fieldEdited(patient,field.name,value)"
                  />
                </template>
              </template>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button'
import MonitoringPatientCell from './MonitoringPatientCell'
import MonitoringDisplayBar from '@/components/Monitoring/MonitoringDisplayBar'
const coachingTypes = [ { name: 'Demi-journée', value: 1 }, { name: 'Groupe', value: 2 }, { name: 'Individuel', value: 3 } ]
export default {
  name: 'MonitoringWaitingEstablishments',
  components: {
   Button,
   MonitoringPatientCell,
   MonitoringDisplayBar
  },
  data () {
    return {
      patientsList: [],
      columns: {
        main: {
          name: 'main',
          title: '',
          display: true,
          hidden: true,
          fields: [
            { title: 'Date', sortable: true, name: 'createdAt', type: 'date'},
            { title: 'Nom', sortable: true, name: 'establishmentName', editable: true},
            { title: 'Type', sortable: true, name: 'establishmentType', editable: true},
            { title: 'Adresse', name: 'address', editable: true, type: 'address'}
          ]
        },
        establishmentInformation: {
          name: 'detail',
          title: 'Detail',
          display: false,
          fields: [
            { title: 'Siret', name: 'establishmentSiret', editable: true},
            { title: 'Téléphone', name: 'establishmentPhone', editable: true},
            { title: 'Email', name: 'establishmentEmail', editable: true},
          ]
        },
        contactInformation: {
          name: 'contact',
          title: 'Contact',
          display: false,
          fields: [
            { title: 'Nom', name: 'lastName', editable: true},
            { title: 'Prénom', name: 'firstName', editable: true},
            { title: 'Email', name: 'email', editable: true},
            { title: 'Fonction', name: 'function', editable: true},
            { title: 'Téléphone', name: 'phone', editable: true},
          ]
        },
        inscription: {
          name: 'commande',
          title: 'Commande',
          display: false,
          fields: [
            { title: 'Nombre d\'oxilios', name: 'deviceQuantity', editable: true, type: 'number'},
            { title: 'Temps d\'engagement (mois)', name: 'engagementInMonths', editable: true, type: 'number'},
            { title: 'Coaching inclus', name: 'includedCoachingsAtLaunch', editable: true, type: 'number'},
            { title: 'Type de coaching', name: 'coachingTypeAtLaunch', editable: true, type: 'select', options: coachingTypes},
            { title: 'Contract', name: 'contractUrl', editable: true, type: 'file'},
            { title: 'Numéro de contrat', name: 'contractNumber', editable: true},
            { title: 'Prix d\'arrivé', name: 'upfrontCost', editable: true, type: 'number'},
            { title: 'Prix mensuel', name: 'monthlyCost', editable: true, type: 'number'},
            { title: '', name:'Valider', type: 'button', action: this.inviteEstablishment}
          ]
        },
        billing: {
          name: 'facturation',
          title: 'Facturation',
          display: false,
          fields: [
            { title: 'Adresse', name: 'billingAddress', editable: true, type: 'address'},
            { title: 'Email', name: 'billingContactEmail', editable: true},
            { title: 'Prénom', name: 'billingContactFirstName', editable: true},
            { title: 'Nom', name: 'billingContactLastName', editable: true},
            { title: 'Téléphone', name: 'billingContactPhone', editable: true},
            { title: 'Business Manager', name: 'businessManager', editable: true, type:'select', options: this.businessManagers},
            { title: 'Manda Sepa', name: 'directDebitOrderUrl', editable: false, type:'file'},
            { title: '', name:'Valider', type: 'button', action: this.validateEstablishment}
          ]
        }
      },
      sortBy: {
        group: 'main',
        key: 'date',
        order: 1
      },
      statusShowed: [22],
      businessManagers:  []
    }
  },
  async created () {
    this.patientsList = await this.$store.dispatch('applications/getEstablishmentsApplication', this.statusShowed)
    await this.$store.dispatch('admin/getBusinessManagers').then(users=>{
      const options = users.map(u=>({name:`${u.firstName} ${u.lastName}`, value:u.id}))
      this.columns.billing.fields.find(f=>f.name === 'businessManager').options = options
    })
  },
  methods: {
    async fieldEdited(patient, key, value) {
      const fields = {}
      let newKey = key
      if(key === 'contractUrl'){
        newKey = 'contractId'
      }else if(key === 'businessManager'){
        newKey = 'businessManagerId'
      }
      fields[newKey] = value
      await this.$store.dispatch('applications/updateEstablishmentApplication', {applicationId: patient.id, fields })
      patient[key] = value
    },
    sort (group, column) {
      if (column.sortable) {
        const { name } = column

        if (this.sortBy.key === name) {
          this.sortBy.order = -this.sortBy.order
        } else {
          this.sortBy = {
            group: group.name,
            key: name,
            order: -1
          }
        }
      }
    },
    async inviteEstablishment(establishment){
     try{
        await this.$store.dispatch('applications/inviteEstablishmentApplication', {applicationId: establishment.id})
        this.$root.$emit("sendToast", {
          message: "Une email vient d'etre envoyé à l'utilisateur",
          type: "success",
        });
     } catch(e) {
        if(e.response?.status == 422){
          this.$root.$emit("sendToast", {
            message: "Cet utilisateur n'est pas eligible",
            type: "warning",
          });
        } else {
          this.$root.$emit("sendToast", {
            message: "Une erreur est survenue lors de l'opération",
            type: "error",
          });
        }
     }
    },
    async validateEstablishment(establishment){
     try{
        await this.$store.dispatch('applications/validateEstablishmentApplication', {applicationId: establishment.id})
        this.$root.$emit("sendToast", {
          message: "La facturation de l'etablissement a été validé",
          type: "success",
        });
     } catch(e) {
        if(e.response?.status == 422){
          this.$root.$emit("sendToast", {
            message: "Cet etablissement n'est pas eligible",
            type: "warning",
          });
        } else {
          this.$root.$emit("sendToast", {
            message: "Une erreur est survenue lors de l'opération",
            type: "error",
          });
        }
     }
    }
  },
  computed: {
    displayColumns () {
      const cols = []
      Object.keys(this.columns).forEach(group => {
        if (this.columns[group].display) {
          cols.push(this.columns[group])
        }
      })
      return cols
    },
    sortedPatients () {
      const order = this.sortBy.order
      const sortBy = this.sortBy.key
      return [...this.patientsList].sort((a, b) => {
        return a[sortBy] > b[sortBy] ? 1 * order : -1 * order
      })
    }
  },
  watch: {
    async statusShowed(statusShowed){
      this.patientsList = await this.$store.dispatch('applications/getEstablishmentsApplication', statusShowed)
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons-container{
  margin-top: 24px;
  display: flex;

  a{
    margin: 0 6px;

    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
  }
}

.monitoring-table{
  display: inline-block;
  overflow: scroll;
  width: 100%;
  padding-bottom: 20px;
}

table{
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  table-layout: fixed;

  thead{
    font-size: 1.1rem;
    font-weight: 420;
    text-align: left;
    background: $main-color;
    color: $white-color;

    .group{
      border-left: 2px solid $white-color;

      &:first-child{
        border-left: none;
      }
    }

    .group-head{
      font-size: 1.2rem;
      text-align: center;
      border-radius: 10px 10px 0px 0px;
    }

    .sortable{
      cursor: pointer;
      transition: all 0.25s;

      &:hover{
        opacity: 0.75;
      }
    }
  }

  tbody{
    tr{
      &:nth-child(2n){
        background: #f7f7f7;
      }
    }
  }

  th, td{
    vertical-align: middle;
  }

  th{
    padding: 10px;
    user-select: none;
    vertical-align: bottom;
  }

  td{
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    padding: 4px;

    &:first-child{
      border-left: none;
    }
    &:last-child{
      border-right: none;
    }
  }

  .separator{
    border-left: 2px solid $main-color;
  }
}
</style>
